import type { CSSProperties, ElementType, ReactNode } from 'react';
import { Container, IconTitleText, Box, Typography, Grid, Badge } from '@repo/ui';
import CheckCircleOutline from '@mui/icons-material/CheckCircleOutline';
import styles from './PageHighlightSection.module.scss';

const NumberTitle = ({ children }: { children: ReactNode; Image?: React.ElementType }) => {
    return (
        <Typography fontWeight="bold" fontSize="2.75rem !important" textAlign="center">
            {children}
        </Typography>
    );
};

const NumberValue = ({ children }: { children: ReactNode }) => {
    return (
        <Typography fontWeight="bold" fontSize="1.25rem !important">
            {children}
        </Typography>
    );
};

const NumberTitleValue = ({ title, value }: { title: string; value?: string }) => {
    return (
        <Box>
            <NumberTitle>{title}</NumberTitle>
            {value && <NumberValue>{value}</NumberValue>}
        </Box>
    );
};

export const DefaultTextChildrenRenderer = ({ children }: { children: ReactNode }) => (
    <Typography textAlign="left">{children}</Typography>
);

export const DefaultTitleH2Renderer = ({ children }: { children: ReactNode }) => (
    <Typography variant="h2" margin="0 0 0.5rem 0">
        {children}
    </Typography>
);

interface HighlightNumber {
    title: string;
    value?: string;
}

interface FeaturedSnippet {
    Icon?: JSX.Element;
    title: string;
    body?: string;
}

interface CardList {
    Component: ElementType;
    itemProps: any[];
}

interface SectionProps {
    title?: string | JSX.Element;
    TitleRenderer?: ElementType;
    subtitle?: string;
    children?: string | JSX.Element;
    imageSrc?: string;
    imageAlt?: string;
    imageWidth?: number;
    imageHeight?: number;
    imagePosition?: 'right' | 'left';
    innerButton?: JSX.Element;
    outerButton?: JSX.Element;
    background?: string;
    padding?: number | string;
    innerPadding?: number | string;
    margin?: number | string;
    innerMargin?: number | string;
    label?: string;
    labelColor?: 'yellow' | 'green' | 'red' | 'default' | 'primary' | 'secondary' | undefined;
    checklist?: string[];
    highlightNumbers?: HighlightNumber[];
    featuredSnippets?: FeaturedSnippet[];
    featuredSnippetsInlined?: boolean;
    cardList?: CardList;
    ChildrenRenderer?: ElementType;
    innerBackgroundColor?: string;
    secondSide?: string | JSX.Element;
    breadCrumbs?: JSX.Element | null;
    featureSnippetStyle?: CSSProperties;
    guestsOnly?: boolean;
    GuestsOnlyView?: ElementType;
    notPaidCustomersOnly?: boolean;
    NotPaidCustomersOnlyView?: ElementType;
    Image?: ElementType;
    childrenFlex?: number;
}

const PageHighlightSection = ({
    title = '',
    TitleRenderer = DefaultTitleH2Renderer,
    subtitle = '',
    children = '',
    imageSrc = '',
    imageAlt = '',
    imageWidth = 0,
    imageHeight = 0,
    imagePosition = 'right',
    innerButton = undefined,
    outerButton = undefined,
    background = '',
    padding = '2rem 0',
    innerPadding = '0',
    margin = '0',
    innerMargin = undefined,
    label = '',
    labelColor = 'yellow',
    checklist = [],
    highlightNumbers = [],
    featuredSnippets = [] as FeaturedSnippet[],
    featuredSnippetsInlined = false,
    cardList = undefined,
    ChildrenRenderer = DefaultTextChildrenRenderer,
    innerBackgroundColor = undefined,
    secondSide = undefined,
    breadCrumbs,
    guestsOnly,
    GuestsOnlyView = Box,
    notPaidCustomersOnly,
    NotPaidCustomersOnlyView = Box,
    Image = 'img',
    childrenFlex = 3,
}: SectionProps) => {
    if ((imageSrc || cardList) && (!Image || Image === 'img')) {
        throw new Error('PageHighlightSection: Image props is required');
    }

    const elementsAlign: any = {
        left: { text: { order: 2 }, image: { order: 1 } },
        right: { text: { order: 1 }, image: { order: 2 } },
    };

    let ClientWrapper: any = Box;
    if (guestsOnly) {
        ClientWrapper = GuestsOnlyView;
    } else if (notPaidCustomersOnly) {
        ClientWrapper = NotPaidCustomersOnlyView;
    }

    if (imageSrc && !imageAlt) {
        throw 'imageAlt is required if imageSrc is used!';
    }

    return (
        <ClientWrapper>
            <Box width="100%" style={{ background, padding, margin }}>
                <Container className={styles['this-platform-container']}>
                    <Box
                        className={`${styles['this-platform-wrapper']} header-stack`}
                        style={{
                            backgroundColor: innerBackgroundColor,
                            padding: innerPadding,
                            margin: innerMargin,
                        }}
                    >
                        <Box
                            className={`${styles['this-platform-text-container']} header-stack-inner`}
                            flex={childrenFlex}
                            style={{ ...elementsAlign[imagePosition].text }}
                        >
                            {/* {breadCrumbs} */}

                            {Boolean(label) && (
                                <Box margin="0 0 0.5rem 0">
                                    <Badge color={labelColor}>{label}</Badge>
                                </Box>
                            )}

                            {Boolean(title) && <TitleRenderer>{title}</TitleRenderer>}

                            {Boolean(subtitle) && (
                                <Typography margin="0" fontSize="2.125rem !important">
                                    {subtitle}
                                </Typography>
                            )}

                            <ChildrenRenderer>{children}</ChildrenRenderer>

                            {Boolean(cardList) && (
                                <Grid container spacing={5}>
                                    {cardList?.itemProps.map((props, index) => (
                                        <Grid key={index} item lg={4} md={4} sm={6} xs={12}>
                                            <cardList.Component {...props} Image={Image} />
                                        </Grid>
                                    ))}
                                </Grid>
                            )}

                            {Boolean(featuredSnippets.length) && (
                                <Grid container margin="0.5rem 0 0 0" gap={3}>
                                    {featuredSnippets.map((snippet: FeaturedSnippet, index) => (
                                        <Grid
                                            key={index}
                                            item
                                            flex={featuredSnippetsInlined ? 1 : undefined}
                                        >
                                            <IconTitleText
                                                icon={snippet.Icon}
                                                title={snippet.title}
                                                body={snippet.body}
                                            />
                                        </Grid>
                                    ))}
                                </Grid>
                            )}

                            {Boolean(checklist.length) && (
                                <Grid container spacing={2}>
                                    {checklist.map((item, index) => (
                                        <Grid
                                            item
                                            key={index}
                                            md={6}
                                            xs={12}
                                            display="flex"
                                            alignItems="center"
                                        >
                                            <CheckCircleOutline
                                                style={{ marginRight: 10, color: 'var(--green)' }}
                                            />
                                            <Typography>{item}</Typography>
                                        </Grid>
                                    ))}
                                </Grid>
                            )}

                            {Boolean(innerButton) && <Box margin="2rem 0 0 0">{innerButton}</Box>}

                            {breadCrumbs}
                        </Box>

                        {Boolean(highlightNumbers.length) && (
                            <Box
                                display="flex"
                                flex={childrenFlex}
                                justifyContent="space-evenly"
                                style={{
                                    ...elementsAlign[imagePosition].image,
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    flexWrap: 'wrap',
                                }}
                            >
                                {highlightNumbers.map((highlightNumber) => (
                                    <NumberTitleValue
                                        key={highlightNumber.title}
                                        title={highlightNumber.title}
                                        value={highlightNumber.value}
                                    />
                                ))}
                            </Box>
                        )}

                        {Boolean(secondSide) && (
                            <Box
                                display="flex"
                                flex={2}
                                style={{
                                    ...elementsAlign[imagePosition].image,
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                {secondSide}
                            </Box>
                        )}

                        {Boolean(imageSrc) && (
                            <Box
                                display="flex"
                                flex={1}
                                style={{
                                    ...elementsAlign[imagePosition].image,
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <Image
                                    loading="lazy"
                                    priority={false}
                                    alt={imageAlt}
                                    title={imageAlt}
                                    className={styles['hands-on-image']}
                                    src={imageSrc}
                                    width={imageWidth}
                                    height={imageHeight}
                                />
                            </Box>
                        )}
                    </Box>

                    {Boolean(outerButton) && (
                        <Box padding="1.5rem 0" display="flex" justifyContent="center">
                            {outerButton}
                        </Box>
                    )}
                </Container>
            </Box>
        </ClientWrapper>
    );
};

export default PageHighlightSection;
