import ArticleCard from '../../atoms/ArticleCard';
import { ArticlePrefix } from '@repo/utils/articleUtils';
import Grid from '../Grid';
import Box from '../../atoms/Box';
import Typography from '../../atoms/Typography';

const ArticleCardGrid = ({
    prefix,
    articleState,
    GuestsOnlyView = () => null,
    JoinNewsletter = () => null,
    Image,
    mainTitle,
    limit,
}: {
    prefix: ArticlePrefix;
    articleState: any;
    GuestsOnlyView?: any;
    JoinNewsletter?: any;
    Image: React.ElementType;
    mainTitle?: string;
    limit?: number;
}) => {
    if (!Image || Image === 'img') {
        throw new Error('ArticleCardGrid: Image props is required');
    }

    const { allArticles } = articleState[prefix];

    if (!allArticles.length) {
        return null;
    }

    const title = `${prefix.charAt(0).toUpperCase()}${prefix.slice(1)}`;
    const description = mainTitle || `All ${title}${prefix === 'blog' ? ' Posts' : ''}`;

    const limitArticles = limit ? allArticles.slice(0, limit) : allArticles;

    return (
        <Box width="100%">
            <Box margin="0 0 2.5rem 0">
                <Typography margin="0 0 1rem 0" variant="h2">
                    {description}
                </Typography>

                <Grid container spacing={4}>
                    {limitArticles.slice(0, 6).map((article: any) => (
                        <ArticleCard
                            Image={Image}
                            key={article.id}
                            article={article}
                            prefix={prefix}
                            belowTheFold
                        />
                    ))}

                    <JoinNewsletter
                        background="var(--surface-color)"
                        margin="2rem 0 0 2rem"
                        GuestsOnlyView={GuestsOnlyView}
                    />

                    {limitArticles.slice(6).map((article: any) => (
                        <ArticleCard
                            Image={Image}
                            key={article.id}
                            article={article}
                            prefix={prefix}
                            belowTheFold
                        />
                    ))}
                </Grid>
            </Box>
        </Box>
    );
};

export default ArticleCardGrid;
