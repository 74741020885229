import fetchWrapper from '@repo/fetcher';

const getMe = (headers) => {
    return fetchWrapper.get(`/me`, {
        headers,
        cache: 'no-store',
    });
};

const loginUser = (data) => {
    return fetchWrapper.post(`/users/login`, data, { cache: 'no-store' });
};

const registerUser = (data) => {
    return fetchWrapper.post(`/users/register`, data, { cache: 'no-store' });
};

const logoutUser = (userId) => {
    return fetchWrapper.post(`/users/${userId}/logout`, { cache: 'no-store' });
};

const updateUserProfile = (userId, userData) => fetchWrapper.put(`/users/${userId}`, userData);

const contactUs = (data) => fetchWrapper.post(`/users/contact_us`, data);

const userService = {
    getMe,
    loginUser,
    registerUser,
    logoutUser,
    updateUserProfile,
    contactUs,
};

export default userService;
